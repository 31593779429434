import React from 'react';
import SEO from '../../components/common/seo';
import Layout from '../../components/common/layout';
import Banner from '../../components/common/hero';
import ProjectsSvg from '../../assets/svgs/projects.svg';

const render = <ProjectsSvg />;

const IndexPage = () => {
  return (
    <>
      <SEO title="About us" description="A nice description about us." />
      <Layout>
        <Banner
          heading="All you need is the right tool for the job"
          description="We've curated a list of resources that will help you achieve your goals"
          ctaText="Lets get started"
        >
          {render}
        </Banner>
      </Layout>
    </>
  );
};

export default IndexPage;
